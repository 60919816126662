.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

	@media (min-width: 768px) {
		.flexi {
			img {
				padding-left: 20px;
			}
		}
	}

	@media (max-width: 767px) {
		.flexi {
			flex-direction: column;
			align-items: center;
		}

		.flexi {
			img {
				margin-bottom: 20px;
				margin-top: 20px;
			}
		}
	}

}