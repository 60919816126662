.unit-pricelist-v3 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	thead {
		/*color: #fff; */
		background: var(--color-grey-light);
	}

	td {
		/*color: #006a74;*/

		.small {
			font-size: 13px;
			color: #777;
		}
	}
}