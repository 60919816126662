.unit-pricelist-v1 {

	.tab-header {
		font-weight: bold;
		margin-bottom: 10px;
		margin-left: 10px;
		font-size: 19px;
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}