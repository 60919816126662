.regionen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner-allgemein {
		background-image: url(RESOURCE/img/banner_allgemein.jpg);
	}

	.inner-banner-ahrenshoop {
		background-image: url(RESOURCE/img/region-banner-ahrenshoop.jpg);
	}

	.inner-banner-born {
		background-image: url(RESOURCE/img/region-banner-born.jpg);
	}

	.inner-banner-wieck {
		background-image: url(RESOURCE/img/region-banner-wieck.jpg);
	}

	.inner-banner-dierhagen {
		background-image: url(RESOURCE/img/region-banner-dierhagen.jpg);
	}

	.inner-banner-prerow {
		background-image: url(RESOURCE/img/region-banner-prerow1.jpg);
	}

	.inner-banner-wustrow {
		background-image: url(RESOURCE/img/Wustrow_2S3A6802 - Kopie.jpg);
		background-position: 50% 56%;
	}


	.img-responsive {
		width: 100%;
	}

	.first-img {
		width: 100%;
		height: 600px;
		object-fit: cover;
		object-position: center;

		@media (max-width: 991px) {
			height: auto;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			height: 500px;
		}
	}

	.darss-zingst-boxes {
		.region-box {
			h3 {
				height: 60px;
			}
		}
	}

	.region-box {
		position: relative;
		margin-bottom: 30px;

		h3 {
			position: absolute;
			bottom: 0;
			margin: 0;
			color: #fff;
			height: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			background: var(--overlay-color);
			font-size: 18px;
			text-align: center;

			@media all and (min-width: 992px) and (max-width: 1199px) {
				font-size: 16px;
			}
		}
	}
}