.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: none;
			font-family: var(--title-font-family-main);
			min-height: 25px;
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.col-lg-3 {
			padding-left: 25px;
			padding-right: 25px;

			@media (max-width: 767px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.sec-col {
			.headline {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}

		.third-col {
			ul {
				li {
					line-height: normal;
					display: block;
				}
			}
		}

		.openings {

			display: flex;
			flex-wrap: wrap;

			ul {
				li {
					line-height: normal;

					&.mb-2 {
						margin-bottom: 15px !important;
					}
				}
			}

			.mt-2 {
				width: 100%;
				padding-right: 10px;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				line-height: 35px;

				@media (max-width: 767px) {
					width: 100%;
				}
			}

			div {
				padding-top: 0;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

					img {
						width: 30px;
					}

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -70px;
			padding-right: 15px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

	.center-col {
		@media (min-width: 991px) {
			justify-content: center;
			display: flex;
		}

	}
}