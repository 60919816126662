.preise-v1 {
	margin-top: var(--page-margin-top);

	table {
		tr {
			td {
				min-width: 60px;

				&:first-child {
					min-width: 130px;
				}

				&:last-child {
					min-width: 200px;
				}
			}
		}

	}

	#accordion {
		.card-header {
			padding: 0;

			.btn.btn-link {
				width: 100%;
				text-align: left;
				position: relative;
				color: #000;
				text-decoration: none !important;

				&:after {
					content: "\f077";
					position: absolute;
					right: 10px;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					color: #000;
					font-size: 15px;
					top: 10px;
				}

				&.collapsed {
					&:after {
						content: "\f078";
					}
				}
			}
		}
	}
}