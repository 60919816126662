.ausstattung-v1 {
	margin-top: var(--page-margin-top);

	.list-view {
		list-style: disc;
		padding: 0 0 0 20px;

		li {
			margin-bottom: 10px;
		}
	}
}