.search-offer-info-v1 {

	background-color: var(--color-white);
	margin-bottom: 1.5rem;

	.offer-title {
		font-family: var(--title-font-family-main);
		font-size: var(--h3-font-size);
		padding-bottom: 10px;
	}

	.offer-legende {
		font-weight: normal;
	}

	.offer-value {
		font-weight: normal;
	}

	.nowrap {
		white-space: nowrap;
	}

	.offer-hint {
		margin-top: 10px;
	}

}