.privacy-v1 {
	margin-top: var(--page-margin-top);

	.heading1 {
		@media (max-width: 500px) {
			word-break: break-word;
			font-size: 25px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}