.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.stars {
		margin-right: 5px;

		.fa-star {
			width: 20px;
			height: 20px;
			background-size: cover;
			background-image: url(RESOURCE/img/icon_star.png);

			&:before {
				display: none;
			}
		}

		.fa-star-o {
			display: none;
		}
	}


	.ul-nopints {
		li {
			display: flex;
			align-items: center;
			margin-bottom: 10px !important;

			img {
				width: 25px;
				margin: 0 15px 0 0;
			}
		}
	}

	.location {
		position: relative;
		padding: 0 0 0 40px;

		.fa {
			margin-right: 0;
			font-size: 25px;
			position: absolute;
			left: 4px;
			top: 0;
			color: #676767;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}