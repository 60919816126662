.anreise-v1 {
	margin-top: var(--page-margin-top);

	.contact-map {
		#map {
			height: 400px !important;
		}
	}

	.button-group {
		margin: 30px 0 0 0;

		.btn-primary {
			margin-right: 15px;
		}
	}
}