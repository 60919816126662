.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
				margin-top: 30px;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.unit-summary-section {
		margin-top: 60px;
		display: none;

		@media (max-width: 1200px) {
			display: block;
		}

		.unit-summary {

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					display: flex;
					align-items: center;
					margin-bottom: 10px !important;
					width: 100%;

					@media all and (min-width: 768px) and (max-width: 1200px) {
						width: 33.33%;
					}

					img {
						width: 25px;
						margin: 0 15px 0 0;
					}
				}
			}

		}
	}

	.youtubevideo {
		margin-bottom: 30px;

		.video {
			height: 450px;

			@media(max-width: 992px) {
				height: 400px;
			}

			@media(max-width: 768px) {
				height: 300px;
			}
		}
	}



}