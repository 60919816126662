.anreise-v2 {
	margin-top: var(--page-margin-top);

	.wustrow {
		#map {
			height: 300px !important;
		}
	}

	.dierhagen {
		#map2 {
			height: 300px !important;
		}
	}

	.luftbild {

		height: 300px !important;

		img {
			object-fit: cover;
			max-height: 300px;

		}
	}

	.button-group {
		margin: 30px 0 0 0;

		.btn-primary {
			margin-right: 15px;
		}
	}
}