.highlights-v1 {
	margin-top: var(--page-margin-top);

	.Highlights-box {
		position: relative;
		margin-bottom: 30px;

		h3 {
			position: absolute;
			bottom: 0;
			margin: 0;
			color: #fff;
			height: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			background: var(--overlay-color);
			font-size: 20px;
			text-align: center;

			@media all and (min-width: 992px) and (max-width: 1199px) {
				font-size: 16px;
			}
		}
	}
}