.stellenangebote-v2 {
	margin-top: var(--page-margin-top);

	@media (max-width: 768px) {
		.flexi {
			flex-direction: column;
			align-items: center;
		}

		.flexi {

			img {
				margin-bottom: 20px;
			}
		}
	}

	.angebote-boxes-v1 {
		.blogcontent {
			margin-bottom: 30px;
		}

		.img-thumbnail {
			padding: 0;
			border: none;
			border-radius: 0;
		}

		.bl-img {
			img {
				height: 450px;
				object-fit: cover;
				width: 100%;

				@media (max-width: 1199px) {
					height: 300px;
				}

				@media (max-width: 767px) {
					height: 350px;
				}
			}
		}

		.btn.btn-primary {
			position: absolute;
			bottom: 60px;
			right: 30px;

			@media (max-width: 767px) {
				position: static;
			}
		}

		.lt-bl-cont {
			padding: 30px;
			background: #f4f4f4;
			padding-bottom: 90px;
			height: 335px;
			overflow: hidden;

			@media (max-width: 1199px) {
				height: 370px;
			}

			@media (max-width: 991px) {
				height: 475px;
			}

			@media (max-width: 767px) {
				height: auto;
				padding-bottom: 30px;
			}

			h4 {
				font-size: 22px;
			}

			p {}
		}
	}
}

.stellenangebote-v1+#lastviewed-units {
	display: none;
}

.blogcontent ul {
	list-style: initial;
	padding-left: 15px;
}